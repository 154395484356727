<template>
  <div class="home">
    <div class="header">
      <!-- 导航栏 -->
      <div class="header-top">
        <div class="header-top-left">
          <img v-if="lang === 'english'" src="../assets/math/logo-b2.png" />
          <img
            class="header-top-left-cn"
            v-else
            src="../assets/math/logo-b3.png"
          />
          <!-- <img v-if="lang === 'english'" src="../assets/math/logo-b-e.png" />
          <img v-else src="../assets/math/logo-b.png" /> -->
        </div>
        <div class="header-top-right">
          <div
            class="header-top-right-recruit text hover"
            @click="handleRecruit"
          >
            {{ tabbar[lang][0] }}
          </div>
          <div class="line"></div>
          <el-dropdown @command="changeLang" placement="bottom" trigger="click">
            <div class="el-dropdown-link text">
              <!-- {{ tabbar[lang][1] }} -->
              {{ lang === 'english' ? 'EN' : '中文' }}
              <img src="../assets/math/down-arrow.png" />
            </div>
            <el-dropdown-menu slot="dropdown" class="dropdown-menu">
              <el-dropdown-item
                command="english"
                class="dropdown-menu-item"
                :class="{ 'dropdown-menu-item_active': lang === 'english' }"
                >EN</el-dropdown-item
              >
              <el-dropdown-item
                command="chinese"
                class="dropdown-menu-item"
                :class="{ 'dropdown-menu-item_active': lang === 'chinese' }"
                >{{ lang === 'english' ? 'CN' : '中文' }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="header-bottom">
        <!-- 大标题 -->
        <div class="header-bottom-title">
          {{ header[lang][0] }}
        </div>
        <!-- 小标题 -->
        <div
          class="header-bottom-subtitle"
          :class="{ 'chinese-subtitle': lang === 'chinese' }"
          @click="toExperience"
        >
          <span>{{ header[lang][1] }}</span>
          <img src="../assets/math/icon_experience.png" alt="" />
        </div>
        <!-- <p class="report_btn" @click="toReport">{{ header[lang][2] }}</p> -->
      </div>
    </div>

    <div class="middle">
      <div v-if="lang === 'english'" class="middle-english">
        <div class="middle-english-title">
          This release includes two datasets TAL-SCQ5K-CN and TAL-SCQ5K-EN, each
          consisting of 5K mathematical competition questions (3K for training
          and 2K for testing). The questions are in the form of multiple-choice
          and cover mathematical topics at the primary, junior high, and high
          school levels. Detailed solution steps are provided to facilitate
          chain-of-thought training.
        </div>
      </div>
      <div v-else class="middle-chinese">
        此次开源的TAL-SCQ5K-CN 和
        TAL-SCQ5K-EN数据集(各3K训练集和2K测试集)，题目为单选形式，涉及小初高阶段数学内容，带有详细的解析步骤便于进行chain-of-thought的训练。
      </div>
      <div class="middle_other">
        <div class="middle_btn">
          <div class="middle_btn_button" @click="download('huggingface')">
            <img src="../assets/math/huggingface.png" /><span
              >huggingface/TAL-SCQ5K</span
            >
          </div>
          <div class="middle_btn_button" @click="download('github')">
            <img src="../assets/math/github.png" /><span>github/TAL-SCQ5K</span>
          </div>
        </div>
      </div>
      <img
        class="middle_data"
        :src="
          require(lang === 'chinese'
            ? '../assets/math/tablePhoneCn.png'
            : '../assets/math/tablePhoneEn.png')
        "
      />
      <div
        class="middle-circle"
        :class="{ 'middle-circle-chinese': lang === 'chinese' }"
      >
        <img src="../assets/math/circle.png" />
      </div>
    </div>

    <div class="recruit">
      <div class="recruit-title">
        {{ recruit[lang][0] }}
      </div>
      <div
        class="recruit-subtitle"
        :class="{ 'chinese-subtitle': lang === 'chinese' }"
        @click="handleRecruit"
      >
        <div class="recruit-subtitle-text">{{ recruit[lang][1] }}</div>
        <div class="recruit-subtitle-img">
          <img src="../assets/math/right-arrow.png" />
        </div>
      </div>
    </div>

    <!-- 底部区域 -->
    <div class="footer">
      <div class="footer-top">
        <img src="../assets/math/shuxuejia.png" />
        <div class="footer-top-name">{{ ftext[lang] }}</div>
        <div class="footer-top-text">{{ fbigtext[lang] }}</div>
      </div>
      <div class="footer-bottom">
        <div class="footer-bottom-logo">
          <img
            v-if="lang === 'english'"
            class="logo1"
            src="../assets/math/logoxtal.png"
          />
          <img v-else class="logo1" src="../assets/math/logoxtal2.png" />
        </div>
        <div v-if="lang === 'english'" class="footer-bottom-text">
          To empower life-long growth with love and technology
          <br />
          MathGPT ©2023-2026
        </div>
        <div v-else class="footer-bottom-text">
          Copyright © 2023 MathGPT 北京世纪好未来教育科技有限公司版权所有
          <br />
          <span @click="toBeian"> 京ICP备13017119号-13 </span>
          ｜
          <span @click="toBeian2"> 京公网安备11010802042225号 </span>
        </div>
      </div>
    </div>

    <!-- 吸顶 -->
    <div v-show="show" class="xiding">
      <div class="xiding-left">
        <img v-if="lang === 'english'" src="../assets/math/logo-w2.png" />
        <img class="xiding-left-cn" v-else src="../assets/math/logo-w3.png" />
        <!-- <img v-if="lang === 'english'" src="../assets/math/logo-w-e.png" />
        <img v-else src="../assets/math/logo-w.png" /> -->
      </div>
      <div class="xiding-right">
        <div class="xiding-right-recruit text hover" @click="handleRecruit">
          {{ tabbar[lang][0] }}
        </div>
        <div class="line"></div>
        <el-dropdown @command="changeLang" placement="bottom" trigger="click">
          <div class="el-dropdown-link text">
            <!-- {{ tabbar[lang][1] }} -->
            {{ lang === 'english' ? 'EN' : '中文' }}
            <img src="../assets/math/down-arrow-banner.png" />
          </div>
          <el-dropdown-menu slot="dropdown" class="dropdown-menu">
            <el-dropdown-item
              command="english"
              class="dropdown-menu-item"
              :class="{ 'dropdown-menu-item_active': lang === 'english' }"
              >EN</el-dropdown-item
            >
            <el-dropdown-item
              command="chinese"
              class="dropdown-menu-item"
              :class="{ 'dropdown-menu-item_active': lang === 'chinese' }"
              >{{ lang === 'english' ? 'CN' : '中文' }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: 'english',
      show: false,
      rendered: false,
      tabbar: {
        english: ['Careers', 'EN'],
        chinese: ['人才招聘', 'CN'],
      },
      language: {
        en: 'english',
        zh: 'chinese',
      },
      header: {
        english: [
          'MathGPT(学而思九章大模型) is an LLM developed by TAL Education Group, which focuses on math related problem solving and lecturing for worldwide research institutions and math enthusiasts.',
          'Try on web',
          'MathGPT Technical Report',
        ],
        chinese: [
          '学而思九章大模型（MathGPT）是好未来自主研发的，面向全球数学爱好者和科研机构，以解题和讲题算法为核心的大模型。',
          '开始体验',
          'MathGPT Technical Report',
        ],
      },
      recruit: {
        english: [
          'We have recruited a group of excellent mathematics teachers, AI scientists and engineers working on MathGPT(学而思九章大模型). We are globally seeking ambitious, resilient, and talented individuals, including but not limited to math geeks, research scientists, infra experts, and data engineers. We look forward to your joining us.',
          'Join us',
        ],
        chinese: [
          '学而思九章大模型（MathGPT）已经凝聚一批优秀且年轻的数学老师、算法科学家和工程师，我们正在全球范围内寻找志向远大、敢于攻坚克难、才华横溢的各类人才，包括且不限于数学极客、算法科学家、infra专家、数据工程师等。期待您的加入。',
          '加入我们',
        ],
      },
      footer: {
        english: 'To empower life-long growth with love and technology',
        chinese: '爱与科技助力终身成长',
      },
      ftext: {
        english: 'David Hilbert, Famous mathematician',
        chinese: '戴维·希尔伯特，著名数学家',
      },
      fbigtext: {
        english: 'Wir müssen wissen,\nWir werden wissen.',
        chinese: '我们必须知道，\n我们必将知道。',
      },
    }
  },
  created() {
    document.title = '九章大模型(MathGPT)'
  },
  mounted() {
    this.lang = this.getUserLang()
    window.addEventListener('scroll', () => {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 0) this.show = true
      else this.show = false
    })
  },
  methods: {
    download(result) {
      window.open(
        result === 'github'
          ? 'https://github.com/math-eval/TAL-SCQ5K'
          : 'https://huggingface.co/datasets/math-eval/TAL-SCQ5K'
      )
    },
    changeLang(lang) {
      this.lang = lang
    },
    handleRecruit() {
      this.$router.push({ name: 'Recruit', query: { lang: this.lang } })
      window.scrollTo({
        top: 0,
      })
    },
    isH5() {
      const reg =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
      if (reg.test(window.navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    getUserLang() {
      const query = this.$route.query
      const syslang = (
        navigator.browserLanguage ||
        navigator.language ||
        navigator.userLanguage
      ).substring(0, 2)
      const lang = query.lang || this.language[syslang] || 'english'
      return lang
    },
    toReport() {
      window.open(
        'https://doc-playground.xes1v1.cn/MathGPT_Technical_Report.pdf'
      )
    },
    toExperience() {
      window.open(
        `https://playground.xes1v1.cn?language=${
          this.lang === 'english' ? 'en' : 'cn'
        }`
      )
    },
    toBeian() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    },
    toBeian2() {
      window.open(
        'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802042225'
      )
    },
  },
}
</script>

<style lang="less" scoped>
.button {
  width: 800px;
  height: 140px;
  font-size: 35px;
  text-align: center;
  margin: 0 100px 100px 0;
  border-radius: 70px;
  line-height: 140px;
  border: 1px solid #ffffff;
  user-select: none;
  cursor: pointer;
}
.home {
  overflow-x: hidden;
  .header {
    width: 100vw;
    padding: calc(11 / 375 * 100vw) calc(22 / 375 * 100vw) 0;
    box-sizing: border-box;
    background: linear-gradient(322.86deg, #9bff1f -35.65%, #77efff 66.14%);
    &-top {
      display: flex;
      justify-content: space-between;
      &-left {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: calc(100 / 375 * 100vw);
          //height: calc(30 / 375 * 100vw);
          object-fit: cover;
          vertical-align: top;
        }
        &-cn {
          width: calc(180 / 375 * 100vw) !important;
        }
      }
      &-right {
        display: flex;
        align-items: center;
        .line {
          width: calc(1 / 375 * 100vw);
          height: calc(10 / 375 * 100vw);
          background: #000;
          margin: 0 74px;
          border-radius: 1px;
        }
        .text {
          font-family: 'PingFang SC';
          font-weight: 400;
          font-size: calc(14 / 375 * 100vw);
          color: #000;
        }
      }
    }
    &-bottom {
      padding: calc(50 / 375 * 100vw) calc(27 / 375 * 100vw)
        calc(50 / 375 * 100vw) 0;
      &-title {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: calc(22 / 375 * 100vw);
        line-height: calc(30 / 375 * 100vw);
      }
      &-subtitle {
        margin: calc(24 / 375 * 100vw) 0 0 0;
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: calc(14 / 375 * 100vw);
        line-height: calc(22 / 375 * 100vw);
      }
    }
  }
  .header-bottom-subtitle {
    width: calc(110 / 375 * 100vw);
    padding: calc(10 / 375 * 100vw) calc(20 / 375 * 100vw);
    border: 1px solid #000000;
    border-radius: calc(36 / 375 * 100vw);
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-family: 'PingFang SC';
      font-weight: 400;
      font-size: calc(14 / 375 * 100vw);
      line-height: 1;
      white-space: nowrap;
    }
    img {
      width: calc(10 / 375 * 100vw);
      margin: 0 0 0 calc(4 / 375 * 100vw);
      display: flex;
      align-items: center;
      img {
        width: 100%;
        object-fit: cover;
        vertical-align: top;
      }
    }
  }
  .report_btn {
    margin-top: calc(24 / 375 * 100vw);
    font-family: 'PingFang SC';
    font-weight: 400;
    font-size: calc(14 / 375 * 100vw);
    line-height: calc(22 / 375 * 100vw);
    position: relative;
    display: inline-block;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: calc(1 / 375 * 100vw);
      background-color: black; /* 设置下划线颜色 */
      margin-bottom: calc(3 / 375 * 100vw); /* 设置下划线与文本底部的间距 */
    }
  }
  .middle {
    width: 100vw;
    box-sizing: border-box;
    background-image: url('../assets/math/bgphone.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: 50% 50%;
    position: relative;
    padding-bottom: 50px;
    &-english {
      padding: calc(40 / 375 * 100vw) calc(49 / 375 * 100vw)
        calc(37 / 375 * 100vw) calc(22 / 375 * 100vw);
      &-title {
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: calc(22 / 375 * 100vw);
        color: #fff;
        position: relative;
        z-index: 1000;
      }
      &-subtitle {
        margin: calc(20 / 375 * 100vw) 0 0 0;
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: calc(14 / 375 * 100vw);
        color: #fff;
      }
    }
    &-chinese {
      padding: calc(40 / 375 * 100vw) calc(49 / 375 * 100vw)
        calc(29 / 375 * 100vw) calc(22 / 375 * 100vw);
      font-family: 'PingFang SC';
      font-weight: 400;
      font-size: calc(22 / 375 * 100vw);
      color: #fff;
      position: relative;
      z-index: 101;
    }
    .middle_other {
      //padding-left: 111px;
      .middle_btn {
        color: #fff;
        .middle_btn_button {
          display: flex;
          width: calc(146 / 375 * 100vw);
          //border: 1px solid rgba(255, 255, 255, 0.50);
          //text-decoration-line: underline;
          border-radius: calc(36 / 375 * 100vw);
          padding: calc(7 / 375 * 100vw) calc(20 / 375 * 100vw);
          margin-bottom: calc(20 / 375 * 100vw);
          //justify-content: center;
          align-items: center;
          font-family: 'PingFang SC';
          span {
            font-weight: 400;
            font-size: calc(14 / 375 * 100vw);
            white-space: nowrap;
            vertical-align: middle;
            font-family: 'PingFang SC';
            position: relative;
            &::after {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 2px;
              background-color: white; /* 设置下划线颜色 */
              margin-bottom: 3px; /* 设置下划线与文本底部的间距 */
            }
          }
          img {
            width: calc(20 / 375 * 100vw);
            vertical-align: middle;
            margin-right: 30px;
          }
        }
      }
    }
    .middle_data {
      margin: 0 auto;
      margin-top: calc(37 / 375 * 100vw);
      width: calc(100% - 222px);
      display: block;
      margin-bottom: calc(40 / 375 * 100vw);
    }
    &-circle {
      position: absolute;
      top: calc(30 / 375 * 100vw);
      right: calc(38 / 375 * 100vw);
      width: calc(74 / 375 * 100vw);
      z-index: 100;
      img {
        width: 100%;
        object-fit: cover;
        vertical-align: top;
      }
    }
    &-circle-chinese {
      top: calc(30 / 375 * 100vw);
      right: calc(38 / 375 * 100vw);
    }
  }

  .recruit {
    width: 100vw;
    padding: calc(29 / 375 * 100vw) calc(49 / 375 * 100vw)
      calc(29 / 375 * 100vw) calc(22 / 375 * 100vw);
    box-sizing: border-box;
    background: linear-gradient(124.5deg, #d9fe9a -36.5%, #60fdc6 54.13%);
    &-title {
      margin: 0 0 calc(20 / 375 * 100vw) 0;
      font-family: 'PingFang SC';
      font-weight: 400;
      font-size: calc(22 / 375 * 100vw);
    }
    &-subtitle {
      width: calc(110 / 375 * 100vw);
      padding: calc(10 / 375 * 100vw) calc(20 / 375 * 100vw);
      border: 1px solid #000000;
      border-radius: calc(22 / 375 * 100vw);
      display: flex;
      justify-content: center;
      align-items: center;
      &-text {
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: calc(14 / 375 * 100vw);
        line-height: 1;
        white-space: nowrap;
        vertical-align: middle;
      }
      &-img {
        width: calc(14 / 375 * 100vw);
        margin: 0 0 0 calc(4 / 375 * 100vw);
        display: flex;
        align-items: center;
        vertical-align: middle;
        img {
          width: 100%;
          object-fit: cover;
          vertical-align: top;
        }
      }
    }
  }

  .footer {
    width: 100vw;
    padding: calc(20 / 375 * 100vw) 0 calc(12 / 375 * 100vw);
    background: #000;
    &-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: calc(70 / 375 * 100vw);
        height: calc(70 / 375 * 100vw);
        object-fit: cover;
        vertical-align: top;
      }
      &-name {
        margin: calc(10 / 375 * 100vw) 0 0 0;
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: calc(14 / 375 * 100vw);
        line-height: 1;
        color: #fff;
      }
      &-text {
        margin: calc(6 / 375 * 100vw) 0 0 0;
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: calc(22 / 375 * 100vw);
        color: #fff;
        white-space: pre-wrap;
        text-align: center;
      }
    }
    &-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-logo {
        margin: calc(30 / 375 * 100vw) 0 0 0;
        img {
          width: calc(258 / 375 * 100vw);
          //height: calc(30 / 375 * 100vw);
          object-fit: cover;
          vertical-align: top;
        }
      }
      &-text {
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: calc(20 / 375 * 100vw);
        transform: scale(0.5);
        color: #fff;
        text-align: center;
        white-space: nowrap;
      }
    }
  }
  .chinese-subtitle {
    width: calc(80 / 375 * 100vw);
  }
  .xiding {
    width: 100vw;
    padding: calc(11 / 375 * 100vw) calc(22 / 375 * 100vw);
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    display: flex;
    justify-content: space-between;
    z-index: 2000;
    &-left {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: calc(100 / 375 * 100vw);
        //height: calc(30 / 375 * 100vw);
        object-fit: cover;
        vertical-align: top;
      }
      &-cn {
        width: calc(180 / 375 * 100vw) !important;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      .line {
        width: calc(1 / 375 * 100vw);
        height: calc(10 / 375 * 100vw);
        background: #fff;
        margin: 0 74px;
        border-radius: 1px;
      }
      .text {
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: calc(14 / 375 * 100vw);
        color: #fff;
      }
    }
  }
}
// 下拉框
.el-dropdown-link {
  display: flex;
  align-items: center;
  img {
    width: calc(10 / 375 * 100vw);
    height: calc(10 / 375 * 100vw);
    margin: 0 0 0 calc(5 / 375 * 100vw);
    object-fit: cover;
    vertical-align: top;
  }
}
// 下拉菜单
.dropdown-menu {
  padding: calc(21 / 375 * 100vw) calc(21 / 375 * 100vw) 0 !important;
  border: 1px solid #000;
  border-radius: calc(10 / 375 * 100vw);
  background: #000;
  &-item {
    padding: 0 0 calc(21 / 375 * 100vw) 0 !important;
    font-family: 'PingFang SC' !important;
    font-weight: 400 !important;
    font-size: calc(14 / 375 * 100vw) !important;
    line-height: 1;
    color: #fff;
    text-align: center;
    &:hover {
      color: #60fdc7 !important;
      background-color: #000 !important;
    }
  }
  &-item_active {
    color: #60fdc7 !important;
  }
  /deep/ .popper__arrow {
    display: none !important;
  }
}
</style>
